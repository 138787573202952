/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/unbound-method */
import { useUserWishlists as useUserWishlistComposable, useWishlist } from '@gemini-vsf/composables';
import { CustomerWishlistsArgs, WishlistQueryVariables } from '@gemini-vsf/api-client/lib/types/GraphQL';
import { ComposableFunctionArgs } from '@vue-storefront/core/lib/src/types';
import { sharedRef } from '@vue-storefront/core';
import { useContext, computed } from '@nuxtjs/composition-api';
import { useApi, useGtm, useGeminiApi, useUser } from '~/composables';
import { GeminiEntityPrefixes } from '~/types/enums';
import { UseUserWishlist, ItemsInWishlistResponse, WishlistItemsCountResponse } from '~/types/types';
import { Product } from '@gemini-vsf/api-client';
import cookieNameEnum from '~/enums/cookieNameEnum';
import { WishlistPermission } from '@gemini-commerce/typescript-client-wishlist';

const ITEMS_IN_WISHLIST = `
  query ItemsInWishlist($wishlistUid: ID, $items: [String]) {
    itemsInWishlist(wishlistUid: $wishlistUid, items: $items) {
      wishlist_ids {
        item_uid
      }
      product_id
    }
  }
`;

const WISHLIST_ITEMS_COUNT = `
  query WishlistItemsCount($wishlistUid: ID) {
    customer {
      wishlist(uid: $wishlistUid) {
        items_count
      }
    }
  }
`;

const useUserWishlists = (): UseUserWishlist => {
  const {
    load: loadUserWishlistsComposable,
    wishlists: userWishlists,
    createUserWishlist: createUserWishlistComposable,
    removeItemFromWishlist: removeItemFromWishlistComposable,
    updateItemInWishlist: updateItemInWishlistComposable,
  } = useUserWishlistComposable();
  const { load: loadWishlistComposable, wishlist, addItem: addItemToWishlistComposable } = useWishlist();
  const { query } = useApi();
  const { addToWishlistPush } = useGtm();
  const { shareWishlist } = useGeminiApi();
  const { user, isAuthenticated } = useUser();

  const {
    app: {
      $vsf: {
        $gemini: {
          config: {
            state: { setWishlist, getWishlist },
          },
        },
      },
      $cookies: appCookies,
    },
  } = useContext();

  const itemsInWishlist = sharedRef<Record<string, boolean>>({}, `useUserWishlists-itemsInWishlist`);
  const numberOfItemsInWishlist = sharedRef<number>(0, `useUserWishlists-numberOfItemsInWishlist`);
  const currentWishlistUid = sharedRef<string>(getWishlist(), `useUserWishlists-currentWishlistUid`);
  const loading = sharedRef<boolean>(false, `useUserWishlists-loading`);

  const resetWishlist = () => {
    setWishlist(null);
    itemsInWishlist.value = {};
    numberOfItemsInWishlist.value = 0;
    currentWishlistUid.value = null;
  };

  const isNotLoggedIn = (): boolean => {
    if (!appCookies.get(cookieNameEnum.customerCookieName)) {
      resetWishlist();
      return true;
    }
    return false;
  };

  const selectableUserWishlists = computed(() => {
    return (
      userWishlists?.value?.items
        ?.map((w) => ({
          label: `${w.label || w.uid}`,
          value: w.uid,
        }))
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())) || []
    );
  });

  const loadNumberOfItemsInWishlist = async () => {
    const wishlistUid = getWishlist();
    if (isNotLoggedIn() || !wishlistUid) return;
    const wishlistResponse: WishlistItemsCountResponse = await query(WISHLIST_ITEMS_COUNT, {
      wishlistUid: getWishlist(),
    });
    numberOfItemsInWishlist.value = wishlistResponse?.data?.customer?.wishlist?.items_count || 0;
  };

  // items is an array of product_grn
  const loadItemsFromList = async (items: string[]) => {
    const wishlistUid = getWishlist();
    if (isNotLoggedIn() || !wishlistUid) return;
    const itemsInWishlistResponse: ItemsInWishlistResponse = await query(ITEMS_IN_WISHLIST, {
      wishlistUid,
      items,
    });
    const tempObj = {};
    itemsInWishlistResponse?.data?.itemsInWishlist?.forEach((itemInWIshlist) => {
      const productId = itemInWIshlist.product_id;
      itemInWIshlist.wishlist_ids.forEach((item) => {
        tempObj[productId] = item.item_uid;
      });
    });
    itemsInWishlist.value = {
      ...itemsInWishlist.value,
      [wishlistUid]: {
        ...itemsInWishlist.value[wishlistUid],
        ...tempObj,
      },
    };
  };

  const loadWishlist = async (params?: ComposableFunctionArgs<WishlistQueryVariables>) => {
    if (isNotLoggedIn()) return;
    loading.value = true;
    await loadWishlistComposable({
      ...params,
      pageSize: 200,
      customQuery: {
        ...(appCookies.get(cookieNameEnum.customerCookieName) ? { customerWishlist: 'customerWishlistCustom' } : { wishlist: 'wishlistCustom' }),
      },
    });
    if (wishlist?.value?.items_count > 0) {
      numberOfItemsInWishlist.value = wishlist.value.items_count;
      await loadItemsFromList(wishlist.value.items.items.map((item) => item.item_grn));
    }
    loading.value = false;
  };

  const loadUserWishlists = async (params?: CustomerWishlistsArgs) => {
    if (isNotLoggedIn()) return;
    loading.value = true;
    await loadUserWishlistsComposable({
      pageSize: 500,
      ...params,
      customQuery: {
        customerWishlists: 'customerWishlistsCustom',
      },
    });
    if (getWishlist()) {
      currentWishlistUid.value = getWishlist();
    }
    if (!getWishlist() && userWishlists?.value?.items?.length > 0) {
      const firstWishlist = userWishlists?.value?.items[0];
      setWishlist(firstWishlist.uid);
      currentWishlistUid.value = firstWishlist.uid;
    }
    loading.value = false;
  };

  const updateItemInWishlist = async ({ wishlist_item_uid, quantity }: { wishlist_item_uid: string; quantity: number }) => {
    if (isNotLoggedIn() || wishlist_item_uid === '') return;
    loading.value = true;
    const wishlistUid = getWishlist() || currentWishlistUid.value;
    await updateItemInWishlistComposable({
      wishlist_item_uid,
      quantity,
      customQuery: {
        updateItemInWishlist: 'updateItemInWishlistCustom',
      },
    });
    await loadWishlist();
    await loadItemsFromList(Object.keys(itemsInWishlist.value[wishlistUid]));
    await loadNumberOfItemsInWishlist();
    loading.value = false;
  };

  const bulkUpdateItemsInWishlist = async (items: { uid: string; quantity: number }[]) => {
    if (isNotLoggedIn() || items?.length === 0) return;
    loading.value = true;
    const wishlistUid = getWishlist() || currentWishlistUid.value;
    await Promise.all(
      items.map((item) => {
        const { uid, quantity } = item;
        return updateItemInWishlistComposable({
          wishlist_item_uid: uid,
          quantity,
          customQuery: {
            updateItemInWishlist: 'updateItemInWishlistCustom',
          },
        });
      })
    );
    await loadWishlist();
    await loadItemsFromList(Object.keys(itemsInWishlist.value[wishlistUid]));
    await loadNumberOfItemsInWishlist();
    loading.value = false;
  };

  const addItemToWishlist = async ({ product, quantity = 0 }: { product: Product; quantity?: number }) => {
    if (isNotLoggedIn()) return;
    loading.value = true;
    await addItemToWishlistComposable({
      product,
      quantity,
      customQuery: {
        addItemToWishlist: 'addItemToWishlistCustom',
      },
    });
    addToWishlistPush(product);
    await loadWishlist();
    const wishlistUid = getWishlist();
    currentWishlistUid.value = wishlistUid;
    const wishlistItemUid = wishlist.value.items.items.find((item) => item.item_grn === product.sku).uid;
    if (!itemsInWishlist.value[wishlistUid]) itemsInWishlist.value[wishlistUid] = {};
    itemsInWishlist.value[wishlistUid || currentWishlistUid.value][
      product.sku
    ] = `${GeminiEntityPrefixes.WishlistItem}${wishlistUid}${wishlistItemUid}`;
    numberOfItemsInWishlist.value = wishlist?.value?.items?.items?.length || Number.parseInt(numberOfItemsInWishlist.value, 10) + 1;
    await loadItemsFromList(Object.keys(itemsInWishlist.value[wishlistUid]));
    loading.value = false;
  };

  const removeItemFromWishlist = async ({ product }: { product: Product }, wishlistItemUid = null) => {
    if (isNotLoggedIn()) return;
    loading.value = true;
    const wishlistUid = getWishlist() || currentWishlistUid.value;
    await removeItemFromWishlistComposable({
      wishlistItemUid:
        wishlistItemUid || itemsInWishlist.value[wishlistUid][product.sku].replace(`${GeminiEntityPrefixes.WishlistItem}${wishlistUid}/`, ''),
      customQuery: {
        removeItemFromWishlist: 'removeItemFromWishlistCustom',
      },
    });
    delete itemsInWishlist?.value?.[wishlistUid][product?.sku];
    await loadWishlist();
    await loadItemsFromList(Object.keys(itemsInWishlist.value[wishlistUid]));
    await loadNumberOfItemsInWishlist();
    loading.value = false;
  };

  const changeWishlist = async (wishlistId: string) => {
    if (isNotLoggedIn()) return;
    loading.value = true;
    const oldWishlistId = getWishlist();
    if (wishlistId !== oldWishlistId) {
      setWishlist(wishlistId);
      currentWishlistUid.value = wishlistId;
      await loadWishlist();
      const wishlistItems = wishlist?.value?.items?.items?.map((item) => item?.product?.sku) || [];
      await loadItemsFromList([
        ...(itemsInWishlist?.value?.[oldWishlistId] ? Object.keys(itemsInWishlist.value[oldWishlistId]) : []),
        ...wishlistItems,
      ]);
    }
    loading.value = false;
  };

  const createUserWishlist = async ({ label, privacy, sharing = true }) => {
    await createUserWishlistComposable({ label, privacy });
    await loadUserWishlists();
    await loadWishlist();
    if (sharing) {
      try {
        const customerAggregationId = user.value.tax_code;
        const wishlistUid = wishlist.value.uid;
        if (!customerAggregationId || !wishlistUid) {
          console.error(
            'createUserWishlist ~ could not share wishlist - missing parameters:',
            'customerAggregationId:',
            customerAggregationId,
            'wishlistUid:',
            wishlistUid
          );
          return;
        }
        await shareWishlist(wishlist.value.uid, WishlistPermission.EditPermission, customerAggregationId);
      } catch (error) {
        console.error('createUserWishlist ~ could not share wishlist - error:', error);
      }
    }
  };

  return {
    loadUserWishlists,
    userWishlists,
    createUserWishlist,
    loadWishlist,
    wishlist,
    addItemToWishlist,
    changeWishlist,
    selectableUserWishlists,
    loadItemsFromList,
    removeItemFromWishlist,
    itemsInWishlist,
    loading,
    numberOfItemsInWishlist,
    loadNumberOfItemsInWishlist,
    currentWishlistUid,
    isAuthenticated,
    resetWishlist,
    updateItemInWishlist,
    bulkUpdateItemsInWishlist,
  };
};

export default useUserWishlists;
